import React, { Component } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Waypoint } from 'react-waypoint';
import { graphql, Link } from 'gatsby';
import FlipMove from 'react-flip-move';
import { FaRegRegistered } from 'react-icons/fa';
import { fadeIn } from 'react-animations';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import SEO from '../components/seo';
import FeaturedSection from '../components/FeaturedSection';
import Layout from '../components/layout';
import { GatsbyImage } from 'gatsby-plugin-image';
import GraphicCircle from '../components/services/assets/compost-a-pak-graphic.svg';
import FarmIcon from '../images/compost-a-pak-at-the-farm-icon.svg';
import ProductionIcon from '../images/compost-a-pak-in-production-icon.svg';
import InHandsIcon from '../images/compost-a-pak-in-your-hands-icon.svg';
import EarthIcon from '../images/compost-a-pak-back-to-the-earth-icon.svg';
import mobileBg from '../images/source-separation-systems-compost-a-pak.jpg';
import singleLeaf from '../images/single-leaf-nogap.png';
import multipleLeaf from '../images/multiple-leaf-nogap.png';
import {
  COLOR_GREEN,
  COLOR_WHITE,
  COLOR_GRAY,
  MAX_WIDTH,
  MAX_WIDTH_SITE,
  screen,
  COLOR_TEXT_GRAY,
} from '../components/common/variables';
import { generatePostURI } from '../components/common/functions';

const bounceAnimation = keyframes`${fadeIn}`;
const ImageContainer = styled.div`
  animation: ${(props) => props.hover && css`0.8s ${bounceAnimation} ease-out`};
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  cursor: pointer;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    img {
      max-width: 230px;
      height: auto;
    }
  }
`;

const ProductContainer = styled(Link)`
  outline: 0 !important;
  border: 0 !important;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  margin: 0 !important;
  max-width: 228px;
  @media ${screen.medium} {
    max-width: 174px;
  }
  @media ${screen.largeXL} {
    max-width: 228px;
  }
  cursor: pointer;
  width: 50%;
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  position: relative;
  flex-direction: row;
  justify-content: space-between !important;
  width: 100%;
  max-width: 937px;
  margin: 50px auto;
  @media ${screen.large} {
    justify-content: center !important;
  }
  @media ${screen.largeXL} {
    justify-content: flex-start !important;
  }

  .product-title {
    padding: 12px 28px;
    font-size: 1.125rem;
    font-weight: 400;
    margin-top: -5px;
    text-align: center;
    @media ${screen.medium} {
      font-size: 1rem;
    }
  }

  .product-thumbnail {
    width: 75%;
    @media ${screen.medium} {
      width: 80%;
    }
  }
  @media ${screen.medium} {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
`;

const Wrapper = styled.div`
  max-width: ${MAX_WIDTH};
  margin: 0 auto;

  .compost-a-pak {
    margin-top: 45px;
    @media ${screen.large} {
      padding: 0;
      margin-top: 75px;
      text-align: center;
    }
    @media ${screen.largeXL} {
      padding: 0;
      margin-top: 90px;
      text-align: center;
    }

    &__page-title {
      font-size: 1.8rem;
      font-weight: 400;
      margin: 0 auto;
      color: #3a3a3a;
      max-width: 385px;
      text-align: center;
      @media ${screen.medium} {
        margin: 0 auto 10px auto;
        font-size: 2.1rem;
        max-width: 900px;
      }
      @media ${screen.largeXL} {
        font-size: 2.4rem;
      }
    }

    &__page-description {
      color: ${COLOR_TEXT_GRAY};
      max-width: 525px;
      font-size: 1rem;
      margin: 0 auto 0 auto;
      padding: 10px 35px 0 35px;
      @media ${screen.largeXL} {
        margin: 30px auto 0 auto;
        text-align: center;
        font-size: 1.1rem;
        max-width: 585px;
        padding: 10px 0 0 0;
      }
      @media ${screen.largeXXL} {
        max-width: 940px;
      }
      strong {
        color: ${COLOR_GRAY};
        font-weight: 300;
        @media ${screen.largeXL} {
          font-weight: 400;
        }
      }

      a {
        color: ${COLOR_GREEN};
        font-weight: 500;
        outline: none;

        &:hover {
          border-bottom: 2px ${COLOR_GREEN} solid;
        }
      }
    }

    &__page-title--green {
      color: ${COLOR_GREEN};
      margin-top: 0;
      margin: 0;
      padding: 0;
      display: block;
      @media ${screen.large} {
        display: inline-block;
      }

      svg {
        font-size: 0.8rem;
        vertical-align: top;
        margin-left: -10px;
      }
    }

    &__product-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      margin-top: 90px;

      li {
        list-style-type: none;
        text-align: center;
        color: ${COLOR_GREEN};
        font-size: 1.1rem;
        margin: 20px 5px;

        h2 {
          margin-top: 15px;
        }
      }
    }
  }
`;

const Banner = styled.div`
  max-width: ${MAX_WIDTH_SITE};
  margin: 0 auto;
  position: relative;

  .large-banner {
    display: none;
    @media ${screen.largeXL} {
      display: block;
    }

    img {
      width: 100%;
      height: auto;
      min-height: 200px;
    }
  }

  .small-banner {
    max-width: 100%;
    position: relative;
    @media ${screen.largeXL} {
      display: none;
    }
  }

  .banner-logo {
    height: 40px;
    position: absolute;
    right: 10%;
    top: 10%;
    transition: 0.5ms;
    @media ${screen.medium} {
      height: 80px;
      transition: 0.5ms;
    }
    @media ${screen.largeXL} {
      display: none;
    }
  }

  h4 {
    font-weight: 400;
    font-family: 'Hero', 'Source Sans Pro', Arial, Helvetica, sans-serif;
    color: #fff;
    margin: 1rem 0rem;
    font-size: 1rem;
  }
`;

const InfoListWrapper = styled.div`
  display: none;
  @media ${screen.largeXL} {
    display: block;
  }
  .information {
    &__svg-highlight-container {
      position: relative;
      position: sticky !important;
      top: 88px;
      z-index: 7;
      width: 100%;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        top: 0;
      }
      background-image: linear-gradient(
        to top,
        transparent 50%,
        ${COLOR_WHITE} 50%
      );
    }

    &__green-bg {
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      width: 100%;
      margin: 0 auto;
      left: 50%;
      max-width: ${MAX_WIDTH_SITE};
      .gatsby-image-wrapper {
        min-height: 250px;
      }
    }

    &__graphic-circle {
      padding: 1.2rem 1rem 1rem 1rem;
    }

    &__single-leaf-img {
      width: 4%;
      position: absolute;
      top: -3px;
      right: 0px;
      overflow: hidden;
    }

    &__multiple-leaf-img-1 {
      width: 9%;
      position: absolute;
      top: 7px;
      left: -54px;
      display: none;
      transform: scale(-1) rotate(90deg);
      @media (min-width: 1372px) {
        display: unset;
      }
    }
    &__multiple-leaf-img-2 {
      width: 11%;
      position: absolute;
      right: 0px;
      bottom: -74px;
    }
    &__svg-highlight {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      max-width: ${MAX_WIDTH};
      @media ${screen.largeXL} {
        margin: 0 auto;
      }
      flex-direction: column;
      @media ${screen.largeXL} {
        flex-direction: row;
      }
      svg {
        width: 240px;
      }
      &__texts {
        padding-top: 20px;
        color: ${COLOR_WHITE};
        padding-right: 20px;
        @media ${screen.largeXL} {
          align-self: center;
          width: auto;
        }
        &--headline {
          margin-bottom: 15px;
        }
        &--subhead {
          font-size: 1.3rem;
          font-weight: 100;
          margin: 10px 0;
        }
        p {
          font-size: 1.1rem;
          margin: 4px 0;
        }
      }

      #farm {
        .svgbg-1 {
          fill: #d8d8d8;
          fill: ${(props) =>
            props.highlightedIcon === 1 ? '#5f9337' : '#d8d8d8'};
        }
      }
      #production {
        .svgbg-2 {
          fill: #b9b9b9;
          fill: ${(props) =>
            props.highlightedIcon === 2 ? '#6cad3f' : '#b9b9b9'};
        }
      }
      #hands {
        .svgbg-3 {
          fill: #999999;
          fill: ${(props) =>
            props.highlightedIcon === 3 ? '#a7ce3e' : '#999999'};
        }
      }
      #earth {
        .svgbg-4 {
          fill: #7a7a7a;
          fill: ${(props) =>
            props.highlightedIcon === 4 ? '#c0e05f' : '#7a7a7a'};
        }
      }
    }

    &__list {
      max-width: ${MAX_WIDTH};
      margin: 90px auto 0 auto;
      display: none;
      @media ${screen.large} {
        padding-left: 35px;
      }
      @media ${screen.largeXL} {
        display: flex;
        flex-direction: column;
        padding: 1rem 166px;
      }
      @media ${screen.largeXXL} {
        padding-left: 0;
      }

      li {
        position: relative;
        list-style: none;
        display: flex;
        padding-bottom: 90px;

        h2 {
          font-size: 1.4rem;
          font-weight: 400;
        }
      }
    }

    &__texts {
      max-width: 650px;
      margin-left: 15px;
    }

    &__title {
      margin-bottom: 15px;
    }

    &__image {
      width: 300px;
      margin-left: 65px;
    }

    &__content {
      p {
        color: ${COLOR_TEXT_GRAY};
        margin-bottom: 15px;
        font-size: 1.1rem;
      }
      h2 {
        color: ${COLOR_GREEN};
        text-align: right;
        margin-top: 45px;
      }
    }

    &__icons {
      width: 40px;
      height: 40px;
      /* position: absolute;
      top: -5px;
      left: -60px; */
    }

    &__bottom-txt {
      text-align: right;
      font-family: 'Hero', 'Source Sans Pro', Arial, Helvetica, sans-serif;
      color: #69ad3f;
      font-weight: 400;
    }
  }
`;

const MobileInfoList = styled.div`
  background-image: url(${mobileBg});
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0 35px 70px 35px;
  color: ${COLOR_WHITE};
  position: relative;
  overflow: hidden;
  @media ${screen.large} {
    padding: 0 55px 70px 55px;
  }

  @media ${screen.largeXL} {
    display: none;
  }

  .graphic-text-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media ${screen.large} {
      justify-content: space-evenly;
    }
  }

  .list-container {
    margin-top: 30px;
  }

  .graphic-img {
    width: 204px;
    @media ${screen.medium} {
      width: 288px;
    }
  }

  .texts {
    max-width: 600px;
    margin: 0;
    @media ${screen.large} {
      max-width: 400px;
      margin: 0 0 0 18px;
    }
  }

  .head-text {
    padding: 40px 0;
    font-size: 1.1rem;
    @media ${screen.medium} {
      font-size: 1.4rem;
    }
    svg {
      font-size: 1.3rem;
      vertical-align: top;
    }
    sup {
      font-family: sans-serif;
    }
  }

  .subhead-text {
    font-weight: 400;
    margin-top: 30px;
  }

  .description {
    max-width: 600px;
    margin-top: 20px;
  }

  .title {
    padding: 15px 0;
    outline: none;
    border-bottom: 1px ${COLOR_WHITE} solid;
    cursor: pointer;
    h3 {
      font-weight: 400;
      font-size: 1.25rem;
    }
  }

  .icons {
    width: 28px;
    filter: brightness(0) invert(1);
    vertical-align: middle;
    margin-right: 20px;
    margin-left: 10px;
  }

  .plus-signs {
    text-align: center;
    vertical-align: middle;
    font-size: 1.7rem;
    color: ${COLOR_WHITE};
    float: right;
    margin-right: 10px;
    font-weight: 100;
  }

  .content {
    padding-bottom: 30px;

    p {
      margin-top: 20px;
    }
  }

  .leaves-img {
    width: 180px;
    position: absolute;
    right: -70px;
    top: 50px;
    opacity: 0.75;
  }
`;

const listIcons = [FarmIcon, ProductionIcon, InHandsIcon, EarthIcon];

const listTextColour = ['#5f9337', '#6cad3f', '#a7ce3e', '#c0e05f'];

class CompostAPakCommercialPage extends Component {
  state = {
    highlightedIcon: '',
    hoveredImage: null,
    openedAccordion: null,
  };

  render() {
    const { acfCompostAPakPage } = this.props.data.content;
    // const { openedAccordion } = this.state;
    const imageBanner =
      acfCompostAPakPage.imageBanner.localFile.childImageSharp.gatsbyImageData;
    const bannerSmall =
      this.props.data.smallScreenBanner.acfCompostAPakPage.imageBanner.localFile
        .childImageSharp.gatsbyImageData;
    const greenBg =
      this.props.data.backgroundImg.childImageSharp.gatsbyImageData;
    const topDescription = this.props.data.content.content;
    const listInfo = acfCompostAPakPage.whyUseCompostAPakList.sort(function (
      a,
      b
    ) {
      return a.number - b.number;
    });

    let _allProducts = this.props.data.normalProducts.edges;

    _allProducts = [
      ..._allProducts,
      // ...this.props.data.normalProducts.edges
    ];

    const prodOrder =
      acfCompostAPakPage.orderOfProducts &&
      acfCompostAPakPage.orderOfProducts.map((order) => order.id);

    let allProducts;

    if (prodOrder) {
      allProducts = _allProducts.filter((p) => !prodOrder.includes(p.node.id));

      for (let i = prodOrder.length; i > -1; i--) {
        const p = _allProducts.find((p) => p.node.id === prodOrder[i]);
        if (p) allProducts.unshift(p);
      }
    } else {
      allProducts = _allProducts;
    }

    const pageTitle =
      (acfCompostAPakPage.seo && acfCompostAPakPage.seo.pageTitle) ||
      'Compost-a-Pak';
    const description =
      acfCompostAPakPage.seo && acfCompostAPakPage.seo.description;

    // Removed duplicated product of Compostable Bin Liners
    const renderProducts = allProducts;

    return (
      <Layout>
        {/* {allProducts.length > 0 &&
          renderProducts.map((t, i) => {
            let url;
            if (
              !t.node.acfCompostAPakProduct.catImage.image ||
              !t.node.acfCompostAPakProduct.mainImages
                .slice(1)
                .find((i) => i.image && i.image.localFile).image
            ) {
              console.error(
                `[Alert] ${t.title} is missing cat_image || main_image[1]`
              );
              url = '/placeholder.jpg';
            } else {
              if (
                !this.state.hoveredImage ||
                this.state.hoveredImage.slug !== t.node.slug ||
                !t.node.acfCompostAPakProduct.mainImages
                  .slice(1)
                  .find((i) => i.image && i.image.localFile)
              ) {
                url = t.node.acfCompostAPakProduct.mainImages
                  .slice(1)
                  .find((i) => i.image && i.image.localFile).image.localFile
                  .childImageSharp.gatsbyImageData.images.fallback.src;
              } else {
                url =
                  t.node.acfCompostAPakProduct.catImage.image.localFile
                    .childImageSharp.gatsbyImageData.images.fallback.src;
              }
            }

            return (
              <div>
                <img
                  key={i}
                  aria-hidden="true"
                  alt="Hovered Image"
                  src={url}
                  style={{ display: 'none' }}
                />
              </div>
            );
          })} */}

        <SEO title={pageTitle} description={description} />
        <Banner>
          <div className="large-banner">
            <GatsbyImage image={imageBanner} alt="Compost-A-Pak Range" />
          </div>
          <div className="small-banner">
            <GatsbyImage image={bannerSmall} alt="Compost-A-Pak Range" />
          </div>
        </Banner>
        <Wrapper>
          <div className="compost-a-pak">
            <h1 className="compost-a-pak__page-title">
              <span className="compost-a-pak__page-title--green">
                Compost-A-Pak <FaRegRegistered />
              </span>{' '}
              Commercial Range
            </h1>
            <div
              className="compost-a-pak__page-description"
              dangerouslySetInnerHTML={{ __html: topDescription }}
            />

            <ItemsContainer>
              <FlipMove
                typeName={null}
                staggerDelayBy={50}
                enterAnimation="fade"
                leaveAnimation="fade"
              >
                {allProducts.length > 0 ? (
                  renderProducts.map((t) => {
                    let url;

                    const isHovered =
                      !!this.state.hoveredImage &&
                      this.state.hoveredImage.slug === t.node.slug;

                    if (
                      !t.node.acfCompostAPakProduct.catImage.image ||
                      !t.node.acfCompostAPakProduct.mainImages
                        .slice(1)
                        .find((i) => i.image && i.image.localFile).image
                    ) {
                      console.error(
                        `[Alert] ${t.title} is missing cat_image || main_images.find(i => i.image && i.image.localFile)`
                      );
                      url = '/placeholder.jpg';
                    } else {
                      if (
                        !this.state.hoveredImage ||
                        this.state.hoveredImage.slug !== t.node.slug ||
                        !t.node.acfCompostAPakProduct.mainImages
                          .slice(1)
                          .find((i) => i.image && i.image.localFile)
                      ) {
                        url =
                          t.node.acfCompostAPakProduct.catImage.image.localFile
                            .childImageSharp.gatsbyImageData.images.fallback
                            .src;
                      } else {
                        url = t.node.acfCompostAPakProduct.mainImages
                          .slice(1)
                          .find((i) => i.image && i.image.localFile).image
                          .localFile.childImageSharp.gatsbyImageData.images
                          .fallback.src;
                      }
                    }

                    return (
                      <ProductContainer
                        key={t.node.id}
                        to={
                          t.node.title === 'Compostable Bin Liners'
                            ? '/compost-a-pak/compostable-bin-liners'
                            : generatePostURI(t)
                        }
                        onMouseEnter={() => {
                          // When   an image is hovered over, set state to show
                          // this one is being hovered, and pick a random
                          // image to show for it on the next render.
                          this.setState({
                            hoveredImage: {
                              slug: t.node.slug,
                            },
                          });
                        }}
                        onMouseLeave={() => {
                          // When its no longer being hovered over, reset.
                          this.setState({ hoveredImage: null });
                        }}
                      >
                        <ImageContainer hover={isHovered}>
                          <img
                            key={t.id}
                            src={url}
                            style={isHovered ? { transition: '5s' } : {}}
                            className="product-thumbnail"
                            alt={t.title}
                          />
                        </ImageContainer>

                        <div
                          className="product-title"
                          style={
                            this.state.hoveredImage &&
                            this.state.hoveredImage.slug === t.node.slug
                              ? { color: '#69ad3f' }
                              : { color: 'rgb(105, 173, 63)' }
                          }
                        >
                          {t.node.title}
                        </div>
                      </ProductContainer>
                    );
                  })
                ) : (
                  <h2>There are no products here!</h2>
                )}
              </FlipMove>
            </ItemsContainer>
          </div>
        </Wrapper>
        <InfoListWrapper highlightedIcon={this.state.highlightedIcon}>
          <div className="information__svg-highlight-container">
            <div className="information__svg-highlight">
              <div className="information__graphic-circle">
                <GraphicCircle />
              </div>
              <div className="information__svg-highlight__texts">
                <h2 className="information__svg-highlight__texts--headline">
                  Why use Compost-A-Pak?
                </h2>
                <p>
                  AT THE FARM we deliberately source waste corn which is
                  traditionally left by farmer to rot.
                </p>
                <p>
                  IN PRODUCTION we manufacture using equipment reengineered for
                  sustainability.
                </p>
                <p>
                  IN YOUR HANDS we place a level of quality that only years of
                  experience can provide.
                </p>
                <p>
                  BACK TO THE EARTH our products breakdown, composting just as
                  nature intended.
                </p>
              </div>
            </div>
            <div className="information__green-bg">
              <GatsbyImage image={greenBg} alt="Green" />

              <img
                className="information__multiple-leaf-img-1"
                src={multipleLeaf}
                alt="leafs"
              />
              <img
                className="information__single-leaf-img"
                src={singleLeaf}
                alt="a leaf"
              />
              <img
                className="information__multiple-leaf-img-2"
                src={multipleLeaf}
                alt="leafs"
              />
            </div>
          </div>

          <ul className="information__list">
            {listInfo.map((info, i) => {
              return (
                <Waypoint
                  topOffset="42%"
                  bottomOffset="57.99%"
                  key={i}
                  onEnter={() => {
                    this.setState({
                      highlightedIcon: info.number,
                    });
                  }}
                >
                  <li>
                    <img
                      className="information__icons"
                      src={listIcons[i]}
                      alt="display icon"
                    />
                    <div className="information__texts">
                      <h2
                        className="information__title"
                        style={{ color: listTextColour[i] }}
                      >
                        {info.title}
                      </h2>
                      <div
                        className="information__content"
                        dangerouslySetInnerHTML={{
                          __html: info.textContent,
                        }}
                      />
                      {i === listInfo.length - 1 && (
                        <Waypoint
                          topOffset="32%"
                          bottomOffset="67.99%"
                          key={i}
                          onEnter={() => {
                            this.setState({
                              highlightedIcon: '1',
                            });
                          }}
                        >
                          <h3 className="information__bottom-txt">
                            ... and so the cycle begins again
                          </h3>
                        </Waypoint>
                      )}
                    </div>
                    <div className="information__image">
                      <GatsbyImage
                        image={
                          info.image.localFile.childImageSharp.gatsbyImageData
                        }
                        alt={info.image.altText || 'Why use Compost-A-Pak'}
                      />
                    </div>
                  </li>
                </Waypoint>
              );
            })}
          </ul>
        </InfoListWrapper>

        <MobileInfoList>
          <img className="leaves-img" src={multipleLeaf} alt="leaves" />
          <h2 className="head-text">
            Why use Compost-A-Pak
            <sup>&reg;</sup>?
          </h2>
          <div className="graphic-text-container">
            <div className="graphic-img">
              <GraphicCircle />
            </div>
            <div className="texts">
              <p className="description">
                AT THE FARM we deliberately source waste corn which is
                traditionally left by farmer to rot.
              </p>
              <p className="description">
                IN PRODUCTION we manufacture using equipment reengineered for
                sustainability.
              </p>
              <p className="description">
                IN YOUR HANDS we place a level of quality that only years of
                experience can provide.
              </p>
              <p className="description">
                BACK TO THE EARTH our products breakdown, composting just as
                nature intended.
              </p>
            </div>
          </div>

          <Accordion
            className="list-container"
            allowZeroExpanded={true}
            onChange={(x) => {
              if (x === this.state.openedAccordion) {
                this.setState({ openedAccordion: null });
              } else {
                this.setState({ openedAccordion: x[0] });
              }
            }}
          >
            {listInfo.map((info, i) => {
              return (
                <AccordionItem key={i}>
                  <AccordionItemHeading className="title">
                    <AccordionItemButton>
                      <h3>
                        <img
                          className="icons"
                          src={listIcons[i]}
                          alt="info icon"
                        />
                        {info.title}
                        <span className="plus-signs">
                          {this.state.openedAccordion === `raa-${i}`
                            ? '-'
                            : '+'}
                        </span>
                      </h3>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div
                      className="content"
                      dangerouslySetInnerHTML={{
                        __html: info.textContent,
                      }}
                    />
                  </AccordionItemPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </MobileInfoList>
        <FeaturedSection content={this.props.data.featuredContent.edges} />
      </Layout>
    );
  }
}

export default CompostAPakCommercialPage;

export const compostAPakData = graphql`
  {
    normalProducts: allWpCompostAPakProduct {
      edges {
        node {
          id
          slug
          nodeType
          title
          acfCompostAPakProduct {
            catImage {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FIXED, width: 400, height: 400)
                  }
                }
              }
            }

            mainImages {
              productCode
              image {
                title
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FIXED, width: 400, height: 400)
                  }
                }
              }
            }
          }
        }
      }
    }

    content: wpPage(id: { eq: "cG9zdDo0MQ==" }) {
      id
      title
      content
      acfCompostAPakPage {
        seo {
          description
          pageTitle
        }
        imageBanner {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        whyUseCompostAPakList {
          number
          title
          textContent
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        orderOfProducts {
          ... on WpCompostAPakProduct {
            id
          }
          ... on WpProduct {
            id
          }
        }
      }
    }

    featuredContent: allWpPost(
      limit: 3
      sort: { fields: modified, order: DESC }
      filter: {
        tags: { nodes: { elemMatch: { name: { eq: "Compost-A-Pak" } } } }
      }
    ) {
      edges {
        node {
          id
          title
          slug
          acfBlog {
            content
            featuredImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 670, height: 490)
                }
              }
            }
          }
        }
      }
    }

    backgroundImg: file(
      relativePath: { eq: "source-separation-systems-compost-a-pak.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1923, height: 274)
      }
    }
    smallScreenBanner: wpPage(id: { eq: "cG9zdDo0MQ==" }) {
      acfCompostAPakPage {
        imageBanner {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 1080, height: 624)
            }
          }
        }
      }
    }
  }
`;
